import Hero from "../components/Hero";
import bg_hero from "../img/bg_hero.png";
import { Link } from "react-router-dom";
import car3 from "../img/car3.jpg";

const Prices = () => {
  return (
    <section className="">
      <div
        className="h-[600px]
  bg-no-repeat bg-cover bg-center py-24"
        style={{ backgroundImage: `url(${bg_hero})` }}
      >
        <div className="container mx-auto flex justify-around h-full">
          <div className="flex flex-col justify-center">
            <div className="text-gray-300 font-semibold flex items-center uppercase">
              <div className=" w-10 h-[2px] bg-yellow-500 mr-3"></div>
              MNT
            </div>

            <h1 className="text-white text-[70px] leading-[1.1] font-light mb-4">
              <span className="font-semibold">PRICING</span>
            </h1>
            <p className="self-start uppercase font-semibold border-b-2 border-yellow-500 text-gray-300 max-w-[262px]">
              Mobile Valeting and Detailing
            </p>
          </div>

          <div className="hidden lg:block"></div>
        </div>
      </div>{" "}
      {/* Hero banner for pricing */}
      <div className="bg-black w-screen h-[770px]">
        <div className="text-white flex flex-cols-2 justify-around p-[50px]">
          <div className="max-w-[850px] hidden md:block">
            <img src={car3} alt="" class="hidden sm:block "></img>
          </div>
          <div>
            <h1 className="text-white text-[70px] leading-[1.1] font-light mb-4 w-max-[150px]">
              <span className="font-semibold">Beyond just a car</span>
            </h1>
            <h1 className="text-white text-[70px] leading-[1.1] font-light mb-4 w-max-[150px]">
              <span className="font-semibold">wash..</span>
            </h1>

            <p class="flex items-center text-1xl">
              <span class="h-1 w-14 bg-yellow-500 mr-2"></span>
              Here at MNT we specialise in Mobile Valeting and Detailing
            </p>
            <p className="max-w-[630px]">
              services that go beyond the ordinary car wash. Our professional
              approach ensures your vehicle's safety while it receives a
              comprehensive treatment, restoring its shine and enhancing its
              appearance both inside and out. With advanced techniques and
              premium, industry recognized products, we deliver exceptional
              results at any location.{" "}
            </p>
          </div>
        </div>
      </div>
      <div className="container mx-auto ">
        <div
          className="flex flex-1 justify-center items-center
          mb-8 lg:mb-0 "
        >
          <div className="mt-[50px] grid grid-row-2">
            <div
              className="grid grid-cols1 sm:grid-cols-1  md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2  max-w-sm
          mx-auto md:max-w-none md:mx-0 mr-[255px]  mb-[50px]"
            >
              <div className="p-[50px] pt-[5px] min-w-[650px] ">
                <p className=" text-3xl font-semibold text-center pb-[5px] p-[30px]">
                  Basic Wash
                </p>
                <div className=" pb-[40px] flex justify-center items-center text-center">
                  <span class="  h-1 w-[350px] bg-yellow-500 mr-2"></span>
                </div>

                <div
                  className="pl-[75px] grid grid-cols1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 max-w-sm
          mx-auto md:max-w-none md:mx-0 justify-start"
                >
                  <ul class="list-disc">
                    <li className="list-none font-semibold">Exterior:</li>
                    <li>Pre Wash</li>
                    <li>Snow Foam</li>
                    <li>2 Bucket Wash Method</li>
                    <li>Wheels Cleaned</li>
                    <li>Hand Dried</li>
                    <li>Body work shined</li>
                    <li>Tyres dressed</li>
                    <li>Windows Cleaned</li>
                  </ul>
                  <ul class="list-disc ">
                    <li className="list-none font-semibold">Interior:</li>
                    <li>Dashboard polished</li>
                    <li>Seats wiped</li>
                    <li>Vacuum</li>
                    <li>Sprayed Air Freshener</li>
                    <li>Inside windows cleaned</li>
                  </ul>
                </div>

                <div className="mt-[50px] text-center text-3xl font-semibold">
                  <p class="flex justify-center items-center text-3xl">
                    LIMITED TIME OFFER!
                  </p>
                  <p class="text-red-900 flex justify-center items-center text-2xl line-through">
                    £50.00
                  </p>
                  <p class="flex justify-center items-center text-3xl">
                    £30.00
                  </p>
                </div>
              </div>
              <div className="p-[50px] pt-[5px] min-w-[650px]">
                <p className="text-3xl font-semibold text-center pb-[5px] p-[30px]">
                  Intermediate Wash
                </p>
                <div className=" pb-[40px] flex justify-center items-center text-center">
                  <span class="  h-1 w-[350px] bg-yellow-500 mr-2"></span>
                </div>

                <div
                  className="pl-[75px] grid grid-cols1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 max-w-sm
          mx-auto md:max-w-none md:mx-0 justify-start"
                >
                  <ul class="list-disc">
                    <li className="list-none font-semibold">Exterior:</li>
                    <li>Pre Wash</li>
                    <li>Snow Foam</li>
                    <li>2 Bucket Wash Method</li>
                    <li>Wheels Cleaned</li>
                    <li>Hand Dried</li>
                    <li>Body work shined</li>
                    <li>Tyres dressed</li>
                    <li>Windows Cleaned</li>
                    <li>Hand Polished</li>
                    <li>Body work shined- 4 weeks protection</li>
                  </ul>
                  <ul class="list-disc ">
                    <li className="list-none font-semibold">Interior:</li>
                    <li>Dashboard polished</li>
                    <li>Seats wiped</li>
                    <li>Vacuum</li>
                    <li>Sprayed Air Freshener</li>
                    <li>Inside windows cleaned</li>
                    <li>interior protected leather cleaned</li>
                  </ul>
                </div>

                <div className="mt-[50px] text-center text-3xl font-semibold">
                  <p class="flex justify-center items-center text-3xl">
                    £70.00
                  </p>
                </div>
              </div>
              <div className="p-[50px] pt-[5px] min-w-[650px]">
                <p className=" text-3xl font-semibold text-center pb-[5px] p-[30px]">
                  Premium Wash
                </p>
                <div className=" pb-[40px] flex justify-center items-center text-center">
                  <span class="  h-1 w-[350px] bg-yellow-500 mr-2"></span>
                </div>

                <div
                  className="pl-[75px] grid grid-cols1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 max-w-sm
          mx-auto md:max-w-none md:mx-0 justify-start"
                >
                  <ul class="list-disc">
                    <li className="list-none font-semibold">Exterior:</li>
                    <li>Pre Wash</li>
                    <li>Snow Foam</li>
                    <li>2 Bucket Wash Method</li>
                    <li>Wheels Cleaned</li>
                    <li>Hand Dried</li>

                    <li>Tyres dressed</li>
                    <li>Windows Cleaned</li>
                    <li>Hand Polished</li>
                    <li>Body work shined</li>
                    <li>Iron contamination and fallout removed</li>
                    <li>Clay bar treatment</li>
                    <li>Car waxed</li>
                  </ul>
                  <ul class="list-disc ">
                    <li className="list-none font-semibold">Interior:</li>

                    <li>Vacuum</li>
                    <li>Sprayed Air Freshener</li>
                    <li>Inside windows cleaned</li>
                    <li>Interior Protected</li>
                    <li>Leather Cleaned</li>
                    <li>Seats deep cleaned</li>
                    <li>Dashboard deep cleaned</li>
                    <li>Leather conditioned</li>
                  </ul>
                </div>

                <div className="mt-[50px] text-center text-3xl font-semibold">
                  <p class="flex justify-center items-center text-3xl">
                    £100.00
                  </p>
                </div>
              </div>
              <div className="p-[50px] pt-[5px] min-w-[650px]">
                <p className=" text-3xl font-semibold text-center pb-[5px] p-[30px]">
                  Stage 1 Polish
                </p>
                <div className=" pb-[40px] flex justify-center items-center text-center">
                  <span class="  h-1 w-[350px] bg-yellow-500 mr-2"></span>
                </div>

                <div
                  className="pl-[75px] grid grid-cols1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 max-w-sm
          mx-auto md:max-w-none md:mx-0 justify-start"
                >
                  <ul class="list-disc">
                    <li className="list-none font-semibold">Exterior:</li>
                    <li>Pre Wash</li>
                    <li>Snow Foam</li>
                    <li>2 Bucket Wash Method</li>
                    <li>Wheels Cleaned</li>
                    <li>Hand Dried</li>

                    <li>Tyres dressed</li>
                    <li>Windows Cleaned</li>

                    <li>Body work shined</li>
                    <li>Iron contamination and fallout removed</li>
                    <li>Clay bar treatment</li>
                    <li>Car waxed</li>
                    <li>Stage 1 machine polish</li>
                  </ul>
                  <ul class="list-disc ">
                    <li className="list-none font-semibold">Interior:</li>

                    <li>Vacuum</li>
                    <li>Sprayed Air Freshener</li>
                    <li>Inside windows cleaned</li>
                    <li>Interior Protected</li>
                    <li>Leather Cleaned</li>
                    <li>Seats deep cleaned</li>
                    <li>Dashboard deep cleaned</li>
                    <li>Leather conditioned</li>
                  </ul>
                </div>

                <div className="mt-[50px] text-center text-3xl font-semibold">
                  <p class="flex justify-center items-center text-3xl">
                    £200.00
                  </p>
                </div>
              </div>
              <div className="p-[50px] pt-[5px] min-w-[650px]">
                <p className=" text-3xl font-semibold text-center pb-[5px] p-[30px]">
                  Stage 1 Polish + Ceramic Coating
                </p>
                <div className=" pb-[40px] flex justify-center items-center text-center">
                  <span class="  h-1 w-[350px] bg-yellow-500 mr-2"></span>
                </div>

                <div
                  className="pl-[75px] grid grid-cols1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 max-w-sm
          mx-auto md:max-w-none md:mx-0 justify-start"
                >
                  <ul class="list-disc">
                    <li className="list-none font-semibold">Exterior:</li>
                    <li>Pre Wash</li>
                    <li>Snow Foam</li>
                    <li>2 Bucket Wash Method</li>
                    <li>Wheels Cleaned</li>
                    <li>Hand Dried</li>

                    <li>Tyres dressed</li>
                    <li>Windows Cleaned</li>

                    <li>Iron contamination and fallout removed</li>
                    <li>Clay bar treatment</li>

                    <li>Stage 1 machine polish correction</li>
                    <li>Ceramic coating - up to 2 years</li>
                  </ul>
                  <ul class="list-disc">
                    <li className="list-none font-semibold">Interior:</li>

                    <li>Vacuum</li>
                    <li>Sprayed Air Freshener</li>
                    <li>Inside windows cleaned</li>
                    <li>Interior Protected</li>
                    <li>Leather Cleaned</li>
                    <li>Seats deep cleaned</li>
                    <li>Dashboard deep cleaned</li>
                    <li>Leather conditioned</li>
                  </ul>
                </div>

                <div className="mt-[50px] text-center text-3xl font-semibold">
                  <p class="flex justify-center items-center text-3xl">
                    £300.00
                  </p>
                </div>
              </div>
              <div className="items-center justify-center">
                <p className="ml-[90px] text-3xl font-semibold text-center pb-[5px] p-[30px] max-w-[450px]">
                  Ready to exceed your expectations?
                </p>
                <div className=" pb-[40px] flex justify-center items-center text-center">
                  <span class="  h-1 w-[350px] bg-yellow-500 mr-2"></span>
                </div>
                <Link
                  to={"/ContactUs"}
                  className="flex items-center justify-center inline-block relative group"
                >
                  <div className="relative">
                    <p className="inline-block font-semibold text-2xl">
                      Get in touch
                    </p>

                    <div className="absolute left-0 w-0 bg-yellow-500 h-0.5 transition-all duration-300 opacity-0 group-hover:opacity-100 group-hover:w-full"></div>
                  </div>
                </Link>
                <p className="mt-[10px] font-semibold text-[10px] text-center">
                  Some vehicles may require custom pricing.
                </p>
                <p className="font-semibold text-[10px] text-center">
                  Contact us for more information.
                </p>
              </div>
            </div>

            <div className="items-center justify-center">
              <div
                className="grid grid-cols1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-[30px] max-w-sm
          mx-auto md:max-w-none md:mx-0"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Prices;
