import React from "react";
import { Link } from "react-router-dom";
import bg_hero from "../img/bg_hero.png";

const Hero = () => {
  return (
    <section
      className="h-[800px]
  bg-no-repeat bg-cover bg-center py-24"
      style={{ backgroundImage: `url(${bg_hero})` }}
    >
      <div className="container mx-auto flex justify-around h-full ">
        <div className="flex flex-col justify-center">
          <div className="text-gray-300 font-semibold flex items-center uppercase">
            <div className=" w-10 h-[2px] bg-yellow-500 mr-3"></div>
            MNT
          </div>

          <div className="">
            <h1 className="text-white text-[50px] lg:text-[70px]  leading-[1.1] font-light mb-4">
              PROFESSIONAL <br />
              <span className="font-semibold">CAR DETAILING</span>
            </h1>
            <p className="self-start uppercase font-semibold border-b-2 border-yellow-500 text-gray-300 max-w-[262px]">
              Mobile Valeting and Detailing
            </p>
          </div>
        </div>

        <div className="hidden lg:block"></div>
      </div>
    </section>
  );
};

export default Hero;
