import React, { useState } from "react";
import axios from "axios";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    option: "",
    carType: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(
        "https://mntdetailing.ew.r.appspot.com/send-email",
        formData
      );
      alert("Email sent successfully");
      setFormData({
        name: "",
        email: "",
        message: "",
        option: "",
        carType: "",
      });
    } catch (error) {
      console.error(error);
      alert("Error: Unable to send email");
    }
  };

  return (
    <div className="max-w-md mx-auto">
      <form
        onSubmit={handleSubmit}
        className="space-y-6 px-8 pt-6 pb-8 mb-4 w-[350px] md:w-[550px]"
      >
        <div className="mb-4">
          <label
            className="block text-white text-sm font-bold mb-2"
            htmlFor="name"
          >
            Name
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="name"
            type="text"
            placeholder="Your Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-white text-sm font-bold mb-2"
            htmlFor="email"
          >
            Email
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="email"
            type="email"
            placeholder="Your Email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-white text-sm font-bold mb-2"
            htmlFor="option"
          >
            Select an Option
          </label>
          <select
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="option"
            name="option"
            value={formData.option}
            onChange={handleChange}
          >
            <option value="">Select</option>
            <option value="Basic Wash">Basic Wash </option>
            <option value="Intermediate Wash">Intermediate Wash </option>
            <option value="Premium Wash">Premium Wash </option>
            <option value="Stage 1 Polish">Stage 1 Polish </option>
            <option value="Stage 1 Polish + Ceramic Coating">
              Stage 1 Polish + Ceramic Coating{" "}
            </option>
            <option value="Not Applicable">Other</option>
          </select>
        </div>

        <div className="mb-4">
          <label
            className="block text-white text-sm font-bold mb-2"
            htmlFor="carType"
          >
            Select Vehicle Type
          </label>
          <select
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="carType"
            name="carType"
            value={formData.carType}
            onChange={handleChange}
          >
            <option value="">Select</option>
            <option value="Car">Car </option>
            <option value="4x4">4x4 </option>
            <option value="Van">Van </option>
            <option value="Not Applicable">Other </option>
          </select>
        </div>

        <div className="mb-6">
          <label
            className="block text-white text-sm font-bold mb-2"
            htmlFor="message"
          >
            Message
          </label>
          <textarea
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="message"
            placeholder="Your Message"
            name="message"
            value={formData.message}
            onChange={handleChange}
          />
        </div>
        <div className="flex items-center justify-between">
          <button
            className="bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
          >
            Send
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
