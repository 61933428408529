import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo1 from "../img/logo1.png";
import logo from "../img/logo.png";

const Header = () => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      window.scrollY > 60 ? setIsActive(true) : setIsActive(false);
    });
  });
  return (
    <header
      className={`${
        isActive
          ? "bg-white py-4 shadow-md text-black font-semibold"
          : "bg-none py-6 text-white"
      } fixed w-full z-10 transition-all`}
    >
      <div
        className="container mx-auto flex items-center 
      justify-between h-full"
      >
        {/* <Link to={"/eComm-website/"}>
          <div>
            <img className="w-[85px]" src={logo1} alt="" />
          </div>
        </Link> */}

        <Link to={"/"}>
          <div>
            <img className="w-[100px]" src={isActive ? logo : logo1} alt="" />
          </div>
        </Link>

        {/* Dont ask */}
        <div>
          <p className=""></p>
        </div>
        <div>
          <p className=""></p>
        </div>
        <div>
          <p className=""></p>
        </div>
        <div>
          <p className=""></p>
        </div>
        <div>
          <p className=""></p>
        </div>
        <div>
          <p className=""></p>
        </div>
        <div>
          <p className=""></p>
        </div>
        <div>
          <p className=""></p>
        </div>

        <Link to={"/"} className="inline-block relative group">
          <div className="relative">
            <p className="inline-block">Home</p>
            <div className="absolute left-0 w-0 bg-yellow-500 h-0.5 transition-all duration-300 opacity-0 group-hover:opacity-100 group-hover:w-full"></div>
          </div>
        </Link>

        <Link to={"/Prices"} className="inline-block relative group">
          <div className="relative">
            <p className="inline-block">Prices</p>
            <div className="absolute left-0 w-0 bg-yellow-500 h-0.5 transition-all duration-300 opacity-0 group-hover:opacity-100 group-hover:w-full"></div>
          </div>
        </Link>

        <Link to={"/ContactUs"} className="inline-block relative group">
          <div className="relative">
            <p className="inline-block">Contact Us</p>
            <div className="absolute left-0 w-0 bg-yellow-500 h-0.5 transition-all duration-300 opacity-0 group-hover:opacity-100 group-hover:w-full"></div>
          </div>
        </Link>

        {/* <div
          onClick={() => setIsOpen(!isOpen)}
          className="cursor-pointer flex relative "
        >
          <BsBag className="text-2xl" />
          <div
            className="bg-black absolute -right-2 -bottom-2
        text-[12px] w-[18px] h-[18px] text-white rounded-full flex 
        justify-center items-center"
          >
            {itemCount}
          </div>
        </div> */}
      </div>
    </header>
  );
};
//36:33
export default Header;
