import Hero from "../components/Hero";
import bg_hero from "../img/bg_hero.png";
import { Link } from "react-router-dom";
import car3 from "../img/car3.jpg";
import ContactForm from "../components/ContactForm";
import igicon from "../img/igicon.png";
import whatsappicon from "../img/whatsappicon.png";
import email from "../img/email.png";
const ContactUs = () => {
  return (
    <section className="">
      <div
        className="h-[600px]
  bg-no-repeat bg-cover bg-center py-24"
        style={{ backgroundImage: `url(${bg_hero})` }}
      >
        <div className="container mx-auto flex justify-around h-full">
          <div className="flex flex-col justify-center">
            <div className="text-gray-300 font-semibold flex items-center uppercase">
              <div className=" w-10 h-[2px] bg-yellow-500 mr-3"></div>
              MNT
            </div>

            <h1 className="text-white text-[70px] leading-[1.1] font-light mb-4">
              <span className="font-semibold">CONTACT US</span>
            </h1>
            <p className="self-start uppercase font-semibold border-b-2 border-yellow-500 text-gray-300 max-w-[262px]">
              Mobile Valeting and Detailing
            </p>
          </div>

          <div className="hidden lg:block"></div>
        </div>
      </div>{" "}
      {/* Hero banner for pricing */}
      <div className="bg-black w-screen min-h-[770px] grid grid-cols-1 lg:grid-cols-2 place-items-center">
        <div className="pt-[50px] ">
          <ContactForm />
        </div>
        <div className="text-white justify-around p-[50px] ml-[25px]">
          <div>
            <h1 className="text-white text-[70px] leading-[1.1] font-light mb-4 w-max-[150px]">
              <span className="font-semibold">Follow us on Social</span>
            </h1>
            <h1 className="text-white text-[70px] leading-[1.1] font-light mb-4 w-max-[150px]">
              <span className="font-semibold">Media!</span>
            </h1>

            <p class="flex items-center text-1xl">
              <span class="h-1 w-14 bg-yellow-500 mr-2"></span>
              Reach out to us on social media and stay up with our content on:
            </p>

            <div className="max-w-[630px]">
              <div className="">
                <div className="flex justify-left">
                  <img
                    src={igicon}
                    alt=""
                    className="max-w-[70px] mt-[10px] ml-[10px]"
                  ></img>
                  <Link
                    to="https://www.instagram.com/MNT_CARCARE/"
                    className=" mt-[30px] text-2xl"
                  >
                    @MNT_CARCARE
                  </Link>
                </div>

                <div className="flex justify-left">
                  <img
                    src={whatsappicon}
                    alt=""
                    className="max-w-[75px] mt-[10px] ml-[6px]"
                  ></img>
                  <p className=" mt-[30px] text-2xl ">+44 7449 004676</p>
                </div>

                <div className="flex justify-left">
                  <img src={email} alt="" className="max-w-[90px]"></img>
                  <p className=" mt-[30px] text-1xl md:text-2xl">
                    MNT@MNTDETAILING.CO.UK
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
