import React from "react";
import ig1 from "../img/ig1.png";
import test1 from "../img/test1.png";
import em1 from "../img/em1.png";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <footer className="mt-[150px] bg-gradient-to-b from-white to-yellow-500 p-12 ">
      <div className="container mx-auto text-center ">
        <p className="font-semibold mt-[50px]">Check out our Socials!</p>
      </div>
      <div className="mr-[15px] grid grid-cols-1 lg:grid-cols-3 justify-between items-center p-[50px] ">
        <div className="flex flex-cols-2 justify-center items-center">
          <img src={ig1} alt="" className="max-h-[30px] mt-[10px] "></img>
          <Link
            to="https://www.instagram.com/MNT_CARCARE/"
            className=" mt-[13px] ml-[10px] "
          >
            @MNT_CARCARE
          </Link>
        </div>

        <div className="flex flex-cols-2 justify-center items-center mt-[20px] md:mt-[0px]">
          <img src={test1} alt="" className="max-h-[30px] mt-[10px]"></img>
          <p className=" mt-[13px] ">+44 7449 004676</p>
        </div>

        <div className="flex flex-cols-2 justify-center items-center mt-[20px] md:mt-[0px]">
          <img src={em1} alt="" className="max-h-[30px]"></img>
          <p className=" mt-[5px] ml-[10px] "> MNT@MNTDETAILING.CO.UK</p>
        </div>
      </div>
      <div className="container mx-auto text-center mt-[150px]">
        <p>Copyright &copy; MNT Detailing 2024. All rights reserved.</p>
      </div>
    </footer>
  );
};
//"bg-white py-4 shadow-md text-black font-semibold"
export default Footer;
