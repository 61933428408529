import React, { useState, useEffect } from "react";
import car1 from "../img/car1.jpg";
import car2 from "../img/car2.jpg";
import car3 from "../img/car3.jpg";
import car4 from "../img/car4.jpeg"; // Import car4

const Carousel = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  // Add car4 to the images array
  const images = [car1, car2, car3, car4];
  const totalSlides = images.length;

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentSlide((prevSlide) =>
        prevSlide === totalSlides - 1 ? 0 : prevSlide + 1
      );
    }, 4500); // Change the interval time here (in milliseconds)

    return () => clearInterval(intervalId);
  }, [totalSlides]);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === totalSlides - 1 ? 0 : prevSlide + 1
    );
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? totalSlides - 1 : prevSlide - 1
    );
  };

  return (
    <div className="relative w-full">
      <div className="relative h-[650px] overflow-hidden rounded-lg">
        <div
          className="absolute left-0 top-0 w-full h-full flex transition-transform duration-700"
          style={{ transform: `translateX(-${currentSlide * 100}%)` }}
        >
          {images.map((image, index) => (
            <div
              key={index}
              className="w-full flex-shrink-0"
              style={{ minWidth: "100%" }}
            >
              <img
                src={image}
                className="w-full h-full object-cover"
                alt={`Slide ${index + 1}`}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="absolute z-30 flex -translate-x-1/2 bottom-5 left-1/2 space-x-3 rtl:space-x-reverse">
        {images.map((_, index) => (
          <button
            key={index}
            type="button"
            className={`w-3 h-3 rounded-full ${
              index === currentSlide ? "bg-gray-600" : "bg-gray-300"
            }`}
            aria-current={index === currentSlide}
            aria-label={`Slide ${index + 1}`}
            data-carousel-slide-to={index}
            onClick={() => setCurrentSlide(index)}
          ></button>
        ))}
      </div>
      <button
        className="hover:bg-gray-400/50 absolute top-1/2 transform -translate-y-1/2 left-0 w-10 h-10 bg-gray-800/50 text-white text-lg flex items-center justify-center rounded-full focus:outline-none"
        onClick={prevSlide}
      >
        {"<"}
      </button>
      <button
        className="hover:bg-gray-400/50 absolute top-1/2 transform -translate-y-1/2 right-0 w-10 h-10 bg-gray-800/50 text-white text-lg flex items-center justify-center rounded-full focus:outline-none"
        onClick={nextSlide}
      >
        {">"}
      </button>
    </div>
  );
};

export default Carousel;
