import valetpro from "./img/valetpro.jpg";
import autoglym from "./img/autoglym.png";
import AutoFinesse from "./img/AutoFinesse.png";
import gyeon from "./img/gyeon.png";

export const brands = [
  {
    img: valetpro,
    href: "",
  },
  {
    img: autoglym,
    href: "",
  },
  {
    img: AutoFinesse,
    href: "",
  },
  {
    img: gyeon,
    href: "",
  },
];
