import Hero from "../components/Hero";
import Carousel from "../components/Carousel";
import { Link } from "react-router-dom";
import car5 from "../img/car5.jpg";
import car2 from "../img/car2.jpg";
import car3 from "../img/car3.jpg";
import car4 from "../img/car4.jpeg"; // Import car4
import Brands from "../components/Brands";
import extclean from "../img/extclean.png";
import intclean from "../img/intclean.png";
import paint from "../img/paint.png";
import polish from "../img/polish.png";
import clay from "../img/clay.png";
import ceramic from "../img/ceramic.png";
const Home = () => {
  return (
    <div>
      <Hero />
      <section className=" ">
        <div className="w-screen bg-black">
          <div className="hidden lg:block">
            <Brands />
          </div>
        </div>

        <div className="bg-black w-screen min-h-[770px] pt-[55px] grid grid-cols-1 lg:grid-cols-2">
          <div className="hidden md:block">
            <Carousel />
          </div>

          <div className="text-white  justify-around p-[50px]">
            <div className="min-w-[850px] mr-[0px] sm:mr-[20px] "></div>
            <div>
              <h1 className="text-white text-[70px] leading-[1.1] font-light mb-4 w-max-[150px]">
                <span className="font-semibold">A little </span>
              </h1>
              <h1 className="text-white text-[70px] leading-[1.1] font-light mb-4 w-max-[150px]">
                <span className="font-semibold">about us..</span>
              </h1>

              <p class="flex items-center text-1xl">
                <span class="h-1 w-14 bg-yellow-500 mr-2"></span>
                Here at MNT we specialise in Valeting and Detailing services
              </p>
              <p className="max-w-[630px]">
                that will go beyond your expectations. Are you tired of swirl
                marks and unsatisfying results? Are you looking for a company
                that you can trust with your vehicle? This is why we're here,
                with our team of trained and experienced professionals we
                provide top services using best equipment and industry
                established products, combined with our, over the years refined,
                techniques we guarantee your satisfaction.{" "}
                <p className="mt-[15px] flex items-center">
                  <span class="h-1 w-14 bg-yellow-500 mr-2"></span>
                  Areas we cover: London, Bedfordshire, Hertfordshire,
                  Buckinghamshire,
                </p>
                <p className="ml-[33px]">Oxfordshire and Cambridgeshire.</p>
              </p>
            </div>
          </div>
        </div>
        <div className=" w-screen min-h-[770px] grid justify-center items-center">
          {/* <div className="text-black flex flex-col-2 justify-around p-[50px]">
            <div>
              <h1 className="text-black text-[70px] leading-[1.1] font-light mb-4 w-max-[150px]">
                <span className="font-semibold">About our </span>
              </h1>
              <h1 className="text-black text-[70px] leading-[1.1] font-light mb-4 w-max-[150px]">
                <span className="font-semibold">services..</span>
              </h1>

              <p class="flex items-center ">
                <span class="h-1 w-14 bg-yellow-500 mr-2"></span>
                Here at MNT we specialise in mobile Valeting and Detailing
              </p>
              <p className="max-w-[630px]">
                <div>
                  <p className="">
                    services to provide our customers with a highest quality and
                    care.
                  </p>

                  <ul class="list-disc mb-[15px]">
                    <li>
                      <p className="font-semibold ">Interior Deep Clean</p>
                    </li>
                    <li>
                      <p className="font-semibold ">Exterior Deep Clean</p>
                      
                    </li>
                    <li>
                      <p className="font-semibold ">Paint Decontamination</p>
                    </li>
                    <li>
                      <p className="font-semibold ">Clay Bar</p>
                    </li>
                    <li>
                      <p className="font-semibold ">Machine Polish</p>
                    </li>
                    <li>
                      <p className="font-semibold ">Ceramic Coating</p>
                    </li>
                  </ul>
                  <Link to={"/Prices"} className="inline-block relative group">
                    <div className="relative ">
                      <p className="inline-block font-semibold ">
                        Discover more
                      </p>
                      <div className="absolute left-0 w-0 bg-yellow-500 h-0.5 transition-all duration-300 opacity-0 group-hover:opacity-100 group-hover:w-full"></div>
                    </div>
                  </Link>
                </div>
              </p>
            </div>
            <div className="max-w-[850px]  hidden md:block ">
              <div class="grid grid-cols-2 gap-2">
                <div className="">
                  <img
                    class="object-cover w-full h-full rounded-lg "
                    src={car5}
                    alt=""
                  />
                </div>
                <div>
                  <img
                    class="object-cover w-full h-full rounded-lg "
                    src={car2}
                    alt=""
                  />
                </div>
                <div>
                  <img
                    class="object-cover w-full h-full rounded-lg "
                    src={car4}
                    alt=""
                  />
                </div>
                <div>
                  <img
                    class="object-cover w-full h-full rounded-lg "
                    src={car3}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div> */}
          <div className="text-center mt-[80px]">
            <p className="text-5xl font-semibold ">Services</p>
            <div className="mt-[5px] pb-[40px] flex justify-center items-center text-center">
              <span class="  h-1 w-[350px] bg-yellow-500 mr-2"></span>
            </div>
          </div>
          <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 ">
            <div className=" max-w-[200px] text-center font-semibold mt-[120px] mx-[150px] text-xl">
              <img src={extclean} alt=""></img>
              <p>Exterior Deep Clean</p>
            </div>
            <div className=" max-w-[200px] text-center font-semibold mt-[120px] mx-[150px] text-xl">
              <img src={intclean} alt=""></img>
              <p>Interior Deep Clean</p>
            </div>
            <div className=" max-w-[200px] text-center font-semibold mt-[120px] mx-[150px] text-xl">
              <img src={paint} alt=""></img>
              <p>Paint Correction</p>
            </div>
            <div className=" max-w-[200px] text-center font-semibold mt-[120px] mx-[150px] text-xl">
              <img src={clay} alt=""></img>
              <p>Clay Bar</p>
            </div>
            <div className=" max-w-[200px] text-center font-semibold mt-[120px] mx-[150px] text-xl">
              <img src={polish} alt=""></img>
              <p>Machine Polish</p>
            </div>
            <div className=" max-w-[200px] text-center font-semibold mt-[120px] mx-[150px] text-xl">
              <img src={ceramic} alt=""></img>
              <p>Ceramic Coating</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
